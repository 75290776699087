var checkedAssetIdsName = "laika_checkedAssetsIds";
var basketSelectedIdsName = "basket_selectedIds";
var reclassifyIdsName = "reclassify_selectedIds";


var watermarkName = "current_watermark";
var laikaPageSizeName = "laika_pageSize"

var searchQueryName = "laika_searchQuery";


function getCheckedAssetIdsFromSession() {
    const checkedAssetsIds = sessionStorage.getItem(checkedAssetIdsName);
    let value = [];

    if (checkedAssetsIds) {
        value = JSON.parse(checkedAssetsIds);
    }

    return value;
}

function getLaikaSearchQueryFromSession() {
    const query = sessionStorage.getItem(searchQueryName);

    return query;
}

function setWatermarkNameToSession(watermark) {
    sessionStorage.setItem(watermarkName, watermark);
}

function setCheckedAssetIdsToSession(checkedAssetsIds) {
    sessionStorage.setItem(checkedAssetIdsName, JSON.stringify(checkedAssetsIds));
}

function setBasketSelectedIdsToSession(selecedIds) {
    sessionStorage.setItem(basketSelectedIdsName, JSON.stringify(selecedIds));
}

function setReclassifySelectedIdsToSession(selecedIds) {
    sessionStorage.setItem(reclassifyIdsName, JSON.stringify(selecedIds));
}

function setLaikaPageSizeToCookie(pageSize){
    setCookie(laikaPageSizeName, pageSize, 30);
}

function setLaikaSearchQueryToSession(query) {
    sessionStorage.setItem(searchQueryName, query);
}

function getBasketSelectedIdsFromSession() {
    const selecedIds = sessionStorage.getItem(basketSelectedIdsName);
    let value = [];

    if (selecedIds) {
        value = JSON.parse(checkedAssetsIds);
    }

    return value;
}

function getReclassifySelectedIdsFromSession() {
    const selecedIds = sessionStorage.getItem(reclassifyIdsName);
    let value = [];

    if (selecedIds) {
        value = JSON.parse(selecedIds);
    }

    return value;
}

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toGMTString();
    console.log(cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/");
    document.cookie = cname + "=" + encodeURIComponent(cvalue) + ";" + expires + ";path=/";
}

function getCookie(cookieName) {
    let name = cookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }

    return "";
}