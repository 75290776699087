$(function () {
    jQuery.validator.addClassRules("field-required", {
        required: true,
        normalizer: function (value) {
            return $.trim(value);
        }
    });

    $('#isAccept').change(function () {
        var $this = $('#submit');
        if ($this.attr('disabled')) {
            $this.removeAttr('disabled');
        }
        else {
            $this.attr('disabled', 'disabled');
        }
    });

    $('.show-password-button').click(function () {
        var $iconButton = $(this);
        var $input = $('.password-input');
        if ($input.prop('type') == 'password') {
            $iconButton.attr('uk-icon', 'hide');
            $input.prop('type', 'text');
        }
        else {
            $iconButton.attr('uk-icon', 'show');
            $input.prop('type', 'password');
        }        
    });

    $('.remember-me').click(function () {
        var $checkbox = $(this);
        const current = $checkbox.val().toLowerCase();

        if (current == "true") {
            $checkbox.val("false");
        } else {
            $checkbox.val("true");
        }
    });
});

function redAlert() {
    let name = $("#userName").val();
    let password = $("#password").val();

    if (!password) {
        $("#password").addClass('border-danger');
    }

    if (!name) {
        $("#userName").addClass('border-danger');
    }
}

function isNameEmpty() {
    let name = $("#userName").val();

    if (name) {
        $("#userName").removeClass('border-danger');
    }
    else {
        $("#userName").addClass('border-danger');
    }
}

function isPasswordEmpty() {
    let password = $("#password").val();

    if (password) {
        $("#password").removeClass('border-danger');
    }
    else {
        $("#password").addClass('border-danger');
    }
}

$("#loginForm").submit(function () {
    var recaptcha = $("#g-recaptcha-response").val();
    if (recaptcha === "") {
        $('#warning').attr("hidden", false);
        return false;
    }  
});

function recaptchaCallback() {
    $('#warning').attr("hidden", true);
}